
import { Layout, Select,Toast, List, Row, Col, Card, Space, TextArea, Tag, Modal, Descriptions, ButtonGroup, Nav, Typography, Tabs, Input, Table, TabPane, Button, Breadcrumb, Skeleton, Avatar } from '@douyinfe/semi-ui';
import { IconBell, IconRefresh2, IconHelpCircle, IconImport, IconBytedanceLogo, IconHome, IconMore, IconHistogram, IconLive, IconSetting } from '@douyinfe/semi-icons';
import { Block, ethers } from 'ethers';

import DemoFlowAnalysisGraph from '../component/fundTracing'
import DemoDualAxes from '../component/DemoDualAxes'

import React, { Component, useState, useEffect } from 'react';




const App = (props) => {

    const { Header, Footer, Sider, Content } = Layout;
    const { Column } = Table;
    const { Text } = Typography;

    const { Meta } = Card;

    const [productDetail, setProductDetail] = useState({});
    const [productStatistics, setProductStatistics] = useState([]);
    const [listingArray, setListingArray] = useState([]);
    const [payingArray, setpayingArray] = useState([]);
    const [trendArray, setTrendArray] = useState([]);
    const [yjProductId, setyjProudctId] = useState("1667494305051283458");


    const dataList = [
        {
            "name": "#9909",
            "price": "9200"
        },
        {
            "name": "#2009",
            "price": "1300"
        },
        {
            "name": "#1888",
            "price": "1190"
        }
    ];

    const style = {
        borderRadius: '4px',
    };

    useEffect(() => {
        const auth_code = localStorage.getItem("auth_code");
        if (auth_code != null && auth_code.length == 20) {
            fetchProductDetail(yjProductId);
            fetchProductOrder(yjProductId);
        }
    }, []);

    

    const fetchProductDetail = async (productId, code) => {
        const res = await fetch( `https://h5api.yanjie.art/api/nft/product/getProductDetailWeb?id=${productId}`);
        const data = await res.json();
        let json = {
            id: data.data.productId,
            pic: data.data.productShareImage,
            price: data.data.price,
            desc: data.data.productDescription,
            name: data.data.productName,
            issueNumber: data.data.issueNumber,
            circulateNumber: data.data.circulateNumber
        }
        localStorage.setItem(productId, JSON.stringify(json))
        setProductDetail(json);
    }

    const fetchProductTrend = async (productId) => {
        const res = await fetch(
            `https://yanjie-api.jikequanzi.workers.dev/trend?id=${productId}`
        );
        const data = await res.json();
        setTrendArray(data);
    }

    const fetchProductOrder = async (productId) => {
        const res = await fetch(
            `https://h5api.yanjie.art/nft/market/getProductMintList?current=1&rows=1000&productId=${productId}&sort=1`
        );
        const data = await res.json();
        const orders = data.rows;

        // 过滤掉值为 0 的元素
        const filteredArray = orders.filter(obj => obj.price !== 0);

        const groupedData = {};

        for (const obj of orders) {
            const tradeStatus = obj.tradeStatus;
            if (!groupedData[tradeStatus]) {
                groupedData[tradeStatus] = [obj];
            } else {
                groupedData[tradeStatus].push(obj);
            }
        }

        const listingNum = groupedData['2'].length;

        const payingNum = groupedData['3'].length;

        const floorPrice = Math.min(...filteredArray.map(obj => obj.price));


        const statis = [
            { key: '发行量', value: JSON.parse(localStorage.getItem(productId)).issueNumber },
            { key: '流通量', value: JSON.parse(localStorage.getItem(productId)).circulateNumber },
            { key: '寄售市场总量', value: data.total },
            { key: '挂单量', value: listingNum },
            { key: '正在交易量', value: payingNum },
            { key: '地板价', value: floorPrice },
        ];
        setListingArray(groupedData['2']);
        setpayingArray(groupedData['3'])
        setProductStatistics(statis);
    }

    const getDateRange = (n) => {
        const end_date = new Date();
        const start_date = new Date(end_date.getTime() - (n - 1) * 24 * 60 * 60 * 1000);
        return { start_date, end_date };
    }


    function convertTimestamp(timestamp) {
        return new Date(parseInt(timestamp) * 1000);
    }

    const verifyCDK = async(code)=>{
        const res = await fetch(
            `https://yanjie.thread3.xyz/verifyCDK?code=${code}`
        );
        const data = await res.json();
        return data;
    }

    const onChange = async(value)=> {
        const auth_code = localStorage.getItem("auth_code");

        if(auth_code === null || auth_code === ''){
            Toast.error("暂未认证");
            return;
        }
        
        const data = await verifyCDK(auth_code);
        if(data.code != 200){
            Toast.error(data.msg);
            return;
        }
        setyjProudctId(value);
        fetchProductDetail(value);
        fetchProductOrder(value);
    };

    return (
        <>
            <Select onChange={onChange} defaultValue={'1667494305051283458'} placeholder="请选择藏品" style={{ width: '280px', marginBottom: '20px' }} size="large">
                <Select.Option value="1667494305051283458">《国画四君子之兰》</Select.Option>
                <Select.Option value="1673271146328461313">《诸子百家-小说家》</Select.Option>
            </Select>
            <Card
                title={
                    <Meta
                        style={{
                            height: 50, width: 1000, color: 'var(--semi-color-text-2)',
                            display: '-webkit-box',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis'
                        }}
                        title={productDetail.name}
                        description={productDetail.desc}
                        avatar={
                            <Avatar shape="square" src={productDetail.pic}></Avatar>
                        }
                    />
                }
                footerLine={true}
                footerStyle={{ display: 'flex', justifyContent: 'flex-end' }}
            >
                <Descriptions data={productStatistics} row size="small" style={style} />
            </Card>

            <Row gutter={{ xs: 16, sm: 16, md: 16, lg: 24, xl: 24, xxl: 24 }}>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12, offset: 0 }}>
                    <Card
                        title='地板价走势'
                        style={{ marginTop: 20 }}
                    >
                        <DemoFlowAnalysisGraph productId={yjProductId} />
                    </Card>
                </Col>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12, offset: 0 }}>

                    <Card
                        title='订单走势'
                        style={{ marginTop: 20 }}
                    >
                        <DemoDualAxes productId={yjProductId} />
                    </Card>

                </Col>
            </Row>



            <Row gutter={{ xs: 16, sm: 16, md: 16, lg: 24, xl: 24, xxl: 24 }}>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12, offset: 0 }}>
                    <Card
                        title={'🪜 正在寄售(' + listingArray.length + ')'}
                        style={{ marginTop: 20 }}
                    >
                        <List
                            style={{ height: 300, overflow: 'scroll' }}
                            dataSource={listingArray}
                            renderItem={item => (
                                <List.Item
                                    style={{ padding: '12px 0px' }}
                                    header={<Avatar shape="square" src={productDetail.pic}></Avatar>}
                                    main={
                                        <div>
                                            <span style={{ color: 'var(--semi-color-text-0)', fontWeight: 500 }}>{productDetail.name} {item.productCode}</span>
                                            <p style={{ color: 'var(--semi-color-text-2)', margin: '4px 0' }}>{item.price}元</p>
                                        </div>
                                    }
                                // extra={
                                //     <ButtonGroup theme="borderless">
                                //         <Button>查看流转记录</Button>
                                //     </ButtonGroup>
                                // }
                                />
                            )}
                        />
                    </Card>
                </Col>

                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12, offset: 0 }}>
                    <Card
                        title={'💰 正在支付(' + payingArray.length + ')'}
                        style={{ marginTop: 20 }}
                    >
                        <List
                            style={{ height: 300, overflow: 'scroll' }}
                            dataSource={payingArray}
                            renderItem={item => (
                                <List.Item
                                    style={{ padding: '12px 0px' }}
                                    header={<Avatar shape="square" src={productDetail.pic}></Avatar>}
                                    main={
                                        <div>
                                            <span style={{ color: 'var(--semi-color-text-0)', fontWeight: 500 }}>{productDetail.name} {item.productCode}</span>
                                            <p style={{ color: 'var(--semi-color-text-2)', margin: '4px 0' }}>{item.price}元</p>
                                        </div>
                                    }
                                // extra={
                                //     <ButtonGroup theme="borderless">
                                //         <Button>查看流转记录</Button>
                                //     </ButtonGroup>
                                // }
                                />
                            )}
                        />
                    </Card>
                </Col>

            </Row>
        </>
    );
}

export default App;