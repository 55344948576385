import React, { useState, useEffect } from 'react';
import { Routes, Route, Outlet, Link } from "react-router-dom";
import { Layout, Toast, Input, List, Modal, Nav, Typography, Button, Breadcrumb, Skeleton, Avatar } from '@douyinfe/semi-ui';
import { IconBell, IconCart, IconPulse, IconVigoLogo, IconLink, IconSemiLogo, IconHelpCircle, IconBytedanceLogo, IconHome, IconHistogram, IconLive, IconSetting } from '@douyinfe/semi-icons';

import Erc from '../pages/erc'
import Question from '../pages/question'

export default function App() {

  const [state, setState] = useState({});

  const [code, setCode] = useState("");



  useEffect(() => {
    const auth_code = localStorage.getItem("auth_code");
    if(auth_code == null){
      setState({visible: true})
    }
  }, []);

  const showDialog = () =>{
    this.setState({
        visible: true,
    });
  }

  const activation = async()=>{

    if(code.length == 0){
      Toast.warning("激活码为空");
      return;
    }

    // 调用激活接口
    const res = await fetch(
      `https://yanjie.thread3.xyz/authentication?code=${code}`
    );
    const data = await res.json();

    if(data.code != 200){
      Toast.error(data.msg)
      return;
    }
    Toast.success("激活成功")
    window.location.reload();
    localStorage.setItem("auth_code", code)
  }

  const handleOk = (e) =>{
    this.setState({
        visible: false,
    });
}
const handleCancel = (e) =>{
    this.setState({
        visible: false,
    });
}



  const data = [
    {
      icon: <IconPulse style={{ fontSize: 48 }} />,
      title: '激活后你可以获得什么？',
      content: <p>1、【市场趋势分析】<br/>2、【公告监控】<br/>3、【XM平台数据信息】<br/>4、【地板价监控】<br/>5、【最优交易策略】<br/>6、【大户监控】</p>,
    },
    {
      icon: <IconCart style={{ fontSize: 48 }} />,
      title: '平台如何收费？',
      content: '目前暂定价【288元】/ 季度',
    },
    {
      icon: <IconSemiLogo style={{ fontSize: 48 }} />,
      title: '平台未来的发展与趋势？',
      content: '未来我们会增加新功能，并维持平台迭代与更新, 帮助大家分析与了解【衍界】平台, 为您屏蔽市场杂音!',
    },
  ];
  const btnStyle = {
    width: 240,
    margin: '4px 20px',
  };
  const footer = (
    <div style={{ textAlign: 'center' }}>
      <Input value={code} onChange={(e) => setCode(e)} style={{ marginBottom: '20px', width: '240px' }} placeholder='输入激活码' size='default'></Input>

      <Button type="primary" theme="solid" onClick={activation} style={btnStyle}>
        激活
      </Button>
      <Button type="primary" theme="borderless" style={btnStyle}>
        联系作者微信（boomtc）
      </Button>
    </div>
  );

  return (
    <div>
      <Modal
        header={null}
        visible={state.visible}
        width="380px"
        onOk={handleOk}
        maskClosable={false}
        onCancel={handleCancel}
        footer={footer}
      >
        <h3 style={{ textAlign: 'center', fontSize: 24, margin: 40 }}>衍界分析平台</h3>
        <List
          dataSource={data}
          split={false}
          renderItem={item => (
            <List.Item
              header={item.icon}
              main={
                <div>
                  <h6 style={{ margin: 0, fontSize: 16 }}>{item.title}</h6>
                  <p style={{ marginTop: 4, color: 'var(--semi-color-text-1)' }}>
                    {item.content}
                  </p>
                </div>
              }
            />
          )}
        />
      </Modal>

      <Routes>
        <Route path="/" element={<Layoutest />}>

          <Route index element={<Erc />} />
          <Route path="analysis" element={<Erc />} />
          <Route path="question" element={<Question />} />
          <Route path="*" element={<NoMatch />} />
        </Route>
      </Routes>
    </div>
  );
}

function Layoutest() {

  const { Header, Footer, Content } = Layout;

  const { Text } = Typography;


  return (
    <div>
      <Layout style={{ border: '1px solid var(--semi-color-border)' }}>
        <Header style={{ backgroundColor: 'var(--semi-color-bg-1)' }}>
          <Nav
            mode={'horizontal'}
            defaultSelectedKeys={['erc']}
            header={{
              logo: <img src="https://www.yanjie.art/assets/logo-114d4c73.png" />,
            }}

            renderWrapper={({ itemElement, isSubNav, isInSubNav, props }) => {
              const routerMap = {
                analysis: "/analysis",
                xmPlatform: "/xmPlatform",
                question: "/question"
              };
              return (
                <Link
                  style={{ textDecoration: "none" }}
                  to={routerMap[props.itemKey]}
                >
                  {itemElement}
                </Link>
              );
            }}
            items={[
              { itemKey: "analysis", text: "数据分析" },
              { itemKey: "xmPlatform", text: "XM平台（soon）" },
              { itemKey: "question", text: "常见问题" },
              // { itemKey: "trc", text: "订单分析" }
            ]}
          ></Nav>
        </Header>
        <Layout>
          {/* <Header style={{ backgroundColor: 'var(--semi-color-bg-1)' }}></Header> */}
          <Content
            style={{
              padding: '24px',
              backgroundColor: 'var(--semi-color-bg-0)',
              height: '100hv'
            }}
          >

            <Outlet />
          </Content>
          <Footer
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: '20px',
              color: 'var(--semi-color-text-2)',
              backgroundColor: 'rgba(var(--semi-grey-0), 1)',
            }}
          >
            <span
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              Author ❤️ &nbsp;<Text link={{ href: 'https://twitter.com/Nuggets1024' }} underline>@Nuggets1024</Text>
            </span>

            <span><Text type="tertiary">{`欢迎各位老板前来合作定制, 邮箱:【boomtc#126.com】`}</Text></span>
          </Footer>
        </Layout>
      </Layout>
    </div>
  );
}

function NoMatch() {
  return (
    <div>
      <h2>暂无该页面</h2>
      <p>
        <Link to="/">返回首页</Link>
      </p>
    </div>
  );
}
