
import { Collapse } from '@douyinfe/semi-ui';
import { IconPlus, IconMinus } from '@douyinfe/semi-icons';



import React, {  useState, useEffect } from 'react';




const App = (props) => {

    

    return (
        <>
            <Collapse defaultActiveKey={['1']} expandIcon={<IconPlus />} collapseIcon={<IconMinus />}>
        <Collapse.Panel header="衍界APP如何下载(IOS问题)？" itemKey="1">
            <p>1. 通过官方链接<a href='https://www.yanjie.art/download'>[点击下载]</a>进行下载 </p>
            <br/>
            <p>2. 安卓可以直接扫码下载并安装 </p>
            <br/>
            <p>3. 苹果有两种下载方式：</p>
            <br/>
            <p>&nbsp;&nbsp;&nbsp;&nbsp;1. 下载TF版本（TestFlight）,  需要先下载TestFlight软件，然后再点击官网的[TF安装包]进行下载</p>
            <br/>
            <p>&nbsp;&nbsp;&nbsp;&nbsp;2. 下载企业版本, 则需要在【设置】-> 【VPN与设备管理】-> 【选择信任“信任公司名称”】</p>
        </Collapse.Panel>
        <Collapse.Panel header="下载后如何进行购买？" itemKey="2">
            <p>1. 【注册账号】, 必须要添加邀请码才能注册。 </p>
            <br/>
            <p>2. 【实名认证】, 您需要年满22周岁，然后完成连连支付的银行卡三要素认证。</p>
            <br/>
            <p>3. 【市场购买】, 完成认证之后可以选择充值余额购买（次日9点可提现），也可以直接从银行卡扣款购买</p>
            <br/>
            <p>4. 【购买类型】, 目前市场上只有【兰】开通了寄售功能可通过平台直接购买，其他藏品需要到一级市场通过【转售】购买。例如：QQ群，微信群</p>
        </Collapse.Panel>
        <Collapse.Panel header="打新利润收益情况如何？" itemKey="3">
            <p>1. 【一期】首发【百家姓】， 首发价格【138】元，目前一级市场价格【2200+】元</p><br/>
            <p>2. 【二期】发售【国画四君子之兰】， 首发价格【89】元，目前一级市场价格【1500+】元</p><br/>
            <p>3. 【三期】发售【国画四君子之梅】， 首发价格【89】元，目前一级市场价格【800+】元</p><br/>
            <p>4. 【四期】发售【国画四君子之菊】， 首发价格【89】元，目前一级市场价格【500+】元</p><br/>
            <p>5. 【五期】发售【国画四君子之竹】， ...</p>
        </Collapse.Panel>
    </Collapse>
            
        </>
    );
}

export default App;