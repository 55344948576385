import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { DualAxes } from '@ant-design/plots';
import { Toast } from '@douyinfe/semi-ui';


const DemoDualAxes = (porps) => {

  const [data, setData] = useState([]);

  let { productId } = porps;

  useEffect( () => {
    const auth_code = localStorage.getItem("auth_code");
    if (auth_code != null && auth_code.length == 20) {
      asyncFetch(productId, auth_code);
    }
  }, [productId]);

  const verifyCDK = async(code)=>{
    const res = await fetch(
        `https://yanjie.thread3.xyz/verifyCDK?code=${code}`
    );
    const data = await res.json();
    return data;
}


  const asyncFetch = (productId, code) => {
    fetch(`https://yanjie.thread3.xyz/trend?id=${productId}&code=${code}`)
      .then((response) => response.json())
      .then((json) => {
        if (json.code === 500 || json.code === 401) {
          Toast.error(json.msg);
          return;
        }
        setData(json)
      })
      .catch((error) => {
        console.log('fetch data failed', error);
      });
  };

  const config = {
    data: [data, data],
    xField: 'createTime',
    yField: ['listingNum', 'payingNum'],

    geometryOptions: [
      {
        geometry: 'line',
        color: '#5B8FF9',
      },
      {
        geometry: 'line',
        color: '#5AD8A6',
      },
    ],
    slider: {
      start: 0,
      end: 1,
      trendCfg: {
        isArea: true,
      },
    },
  };
  return <DualAxes {...config} />;
};

export default DemoDualAxes;
